import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import utils from "../utils/utils"

const IPIframe = () => {
  let { sessionState } = useAuth();
  let stat = "unchanged";
  var mes = "6f64e789-1d24-4498-a089-838a7c021270 " + sessionState;
  // var targetOrigin = "http://localhost:3001"; // Validates origin
  var targetOrigin = "https://sso.automatrix.et"; // Validates origin
  var opFrameId = "opIframe";
  var timerID;

  function check_session() {
    var win = window.parent.frames[opFrameId].contentWindow;
    console.log("pinging OPIFrame: ", mes);
    win.postMessage(mes, targetOrigin);
  }

  function setTimer() {
    check_session();
    timerID = setInterval(check_session, 5 * 1000);
  }

  window.addEventListener("message", receiveMessage, false);

  function receiveMessage(e) {
    // console.log("IPIFrame: recieved message: ", e.data);
    if (e.origin !== targetOrigin) {
      console.log("IPIFrame: invalid origin ", e.origin);
      return;
    }

    stat = e.data;

    if (stat === "changed") {
      console.log("ipiFrame: stat changed");
      clearInterval(timerID);
      // ask for grant with prompt none
      // window.location.href = "http://localhost:8000/v1/oauth/authorize?client_id=0e3e21f0-5107-4610-ba4d-c42653119f2a&redirect_uri=http://localhost:3000/authorize&scope=openid&state=state&response_type=code&prompt=none";
      // window.location.href = "https://sso.automatrix.et/v1/oauth/authorize?client_id=6f64e789-1d24-4498-a089-838a7c021270&redirect_uri=http://localhost:3000/authorize&scope=openid&state=state&response_type=code&prompt=none";
      utils.LoginRedirect("none")
      // if success update refresh and access tokens
      // if failed remove refresh and access tokens
    } else if (stat === "error") {
      console.log("error")
      clearInterval(timerID)
    }
    else {
      console.log("no change");
    }
  }

  // useEffect(() => {
  //   console.log("timer set")
  //   setTimer()
  // })
  setTimer();
  // useEffect(()=>()=> {
  //   console.log("clearing interval")
  //   clearInterval(timerID)
  // },[])
  return <div>you can't see me</div>;
};

export default IPIframe;
