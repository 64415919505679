import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import SignIn from "./Pages/signIn";
import { CssBaseline, Hidden, Slide } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./utils/theme";
import { SnackbarProvider } from "notistack";
import Main from "./Pages/main";
import PrivateRoute from "./components/privateRoute";
import { AuthProvider } from "./context/AuthProvider";
import PersistLogin from "./Pages/persistLogin";
import Home from "./Pages/home";
import Authorize from "./components/authorize";
import IPIframe from "./Pages/ipIframe";
import Listener from "./components/listener";
const queryClient = new QueryClient();

function App() {
  return (
    <div>
      {/* Single Sign out Iframe*/}
      {window.parent === window && (
        <iframe
          id="ipIframe"
          src="https://demosso.automatrix.et/IPIFrame"
          title="ipIframe"
          style={{display:"none"}} 
        ></iframe>
      )}
      {window.parent === window && (
        <iframe
          id="opIframe"
          src="https://sso.automatrix.et/opIframe"
          title="opIframe"
          style={{display:"none"}}
        ></iframe>
      )}
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          TransitionComponent={Slide}
        >
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <AuthProvider>
                {window.parent === window && (
                  <Hidden>
                    <Listener id="listener" />
                  </Hidden>
                )}
                <Routes>
                  <Route path="login" element={<SignIn />} />
                  <Route path="authorize" element={<Authorize />} />
                  <Route path="IPIFrame" element={<IPIframe />} />
                  <Route element={<PersistLogin />}>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <Main />
                        </PrivateRoute>
                      }
                    >
                      <Route index element={<Home />} />
                    </Route>
                  </Route>
                </Routes>
              </AuthProvider>
            </BrowserRouter>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
