import axios from 'axios';
// export const BASE_URL = "http://localhost:5432";
// export const BASE_URL = "http://206.189.54.235:5432";
export const BASE_URL = "https://demosso.automatrix.et/api";
// export const RFRESH_TOKEN_BASE_URL = "http://206.189.54.235:5432";
export const VERSION = "v1";

export default axios.create({
    baseURL: `${BASE_URL}`
});

// export const axiosRefresh = axios.create({
//     baseURL: `${RFRESH_TOKEN_BASE_URL}/${VERSION}`
// });
export const axiosPrivate = axios.create({
    baseURL: `${BASE_URL}/${VERSION}`,
    headers: { 'Content-Type': 'application/json' }
});
