import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Home() {
    const axiosPrivate = useAxiosPrivate();
    const { data } = useQuery(["nearby"], () => {
        return axiosPrivate.get(`/nearby`);
    },
        {
            refetchOnWindowFocus: false,
            enable: false
        }
    );

    // RENDER
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}
        >
            {data?.data?.items?.map((item) =>
                <Card sx={{ maxWidth: "30%", mx: 2, height: "50vh" }} key={item.id}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={item.logo}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {item.description}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
}
export default Home;