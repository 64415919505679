import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import logo from "../utils/images/ridePlus.png";
import { useContext, useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import utils from "../utils/utils";

function SignIn() {
  // USEFORM HOOK
  const {
    handleSubmit,
  } = useForm({});

  const { persist, setPersist } = useContext(AuthContext);

  useEffect(()=>{
    localStorage.setItem("test", persist);
  },[persist])
  
  // HELPERS
  const onSubmit = () => {
    setPersist(true);
    // window.location.href = "http://localhost:8000/v1/oauth/authorize?client_id=0e3e21f0-5107-4610-ba4d-c42653119f2a&redirect_uri=http://localhost:3000/authorize&scope=openid&state=state&response_type=code&prompt=consent";    
    // window.location.href = "https://sso.automatrix.et/v1/oauth/authorize?client_id=0e3e21f0-5107-4610-ba4d-c42653119f2a&redirect_uri=http://localhost:3000/authorize&scope=openid&state=state&response_type=code&prompt=consent";    
    utils.LoginRedirect("consent")
  };
  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxShadow: 1,
            p: 2,
          }}
        >
          <Box 
            sx={{
              display: "flex",
              flexDirection:"column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: "100px", height: "80px", m: 1, borderRadius: 0 }}
              src={logo}
            />
            <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              Sign In with Ride Plus SSO
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ my: 2, mx:2 }}>
            <Divider />
            <Button
              type="submit"
              fullWidth
              size="medium"
              variant="contained"
              sx={{
                my: 1.5,
                color: "primary.paper",
                textTransform: "none"
              }}
            >
              Sign In with Ride Plus SSO
            </Button>
            <Divider />
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default SignIn;