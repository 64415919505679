import { useEffect } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
/// ?code=sldfjsdlkjf&session_state=sdfjsldfj
// ?error=sldkfjsdlfj&error_description=sldkfjdlfj
function Authorize() {
  const navigate = useNavigate();
  const location = useLocation();
  let { setAuth } = useAuth();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  console.log("params from kira ", params);
  console.log("window location ", window.location);

  const { mutate } = useMutation((code) => {
    return axios.post("/sso/accessToken", {
      code: code,
      redirect_uri: "https://demosso.automatrix.et/authorize",
      grant_type: "authorization_code",
    });
  });

  useEffect(() => {
    if (params.session_state) {
      console.log(
        "params get all 2",
        params.session_state,
        params.sessionState
      );
      localStorage.setItem("session_state", params.session_state);
    }
    if (params.code) {
      console.log("params get all", params.session_state, params.sessionState);
      mutate(params.code, {
        onSuccess: (data) => {
          localStorage.setItem(
            "refresh token",
            data?.data?.data?.refresh_token
          );
          console.log("nati:", location.pathname);
          // if (location.pathname !== "/IPIFrame") {
            setAuth(data?.data?.data);
            navigate("/");
            window.parent.postMessage("login-success","*")
          // }
        },
        onError: (error) => {
          console.error({
            error,
            msg: error?.response?.data?.error?.message,
            default: error?.message,
          });
        },
      });
    }
    if (params.error) {
      console.log("automatic login failed");
      localStorage.removeItem("refresh token");
      window.parent.postMessage("login-fail","*")
    }
  }, [
    mutate,
    navigate,
    params.code,
    params.error,
    params.session_state,
    setAuth,
  ]);
}
export default Authorize;
