import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    // const [sessionState, setSessionState] = useState("");
    const [sessionState, setSessionState] = useState(localStorage.getItem('session_state') || "")
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("test")) || false);

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist, sessionState, setSessionState }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;