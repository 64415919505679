import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Listener = () => {
  const listener = (e) => {
    if (typeof e.data !== "string") return;
    console.log("Listener: new message", e.data);
    if (e.data === "login-success") {
      navigate("/");
    } else if (e.data === "login-fail") navigate("/login");
  };
  useEffect(
    () => () => {
      console.log("removing listener");
      window.removeEventListener("message", listener);
    },
    []
  );
  console.log("registered listener");
  const navigate = useNavigate();
  window.addEventListener("message", listener);
  return <div></div>;
};

export default Listener;
