import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const {setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.post('/sso/accessToken', {
           refresh_token: window.localStorage.getItem("refresh token"),
           grant_type: "refresh_token"
        });

        if(response){
            window.localStorage.setItem("refresh token", response?.data?.data?.refresh_token)
        }
        
        setAuth(prev => {
            prev = response.data.data;
            return prev;
        });

        return response.data.data.access_token;
    }
    return refresh;
};

export default useRefreshToken;
