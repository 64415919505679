import useAuth from "../hooks/useAuth";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  let { auth } = useAuth();

  if (!auth.access_token) {
    return <Navigate to="/login" />;
  }
  return children;
}

export default PrivateRoute;
